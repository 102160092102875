import camelcaseKeys from 'camelcase-keys';
import { isArray } from 'lodash';
import axios, { AxiosResponse } from 'axios';
import urlcat from 'urlcat';
import { updateUserInfo } from '../store/actions/appActions';
import Logger from './logger';
import { getCooeeApiUrl, getHostEnv } from './api';
import { defaultStore } from '../store';

export const extractUser = (json) => {
  const ccJson = camelcaseKeys(json);
  return [ccJson].map((e) => ({
    sessionToken: e.sessionToken,
    sessionId: e.sessionId,
    id: e.id,
    name: e.name,
    firstName: e.firstName,
    lastName: e.lastName,
    email: e.email,
    lastLoginDate: e.lastLoginDate,
  }))[0];
};

export const getCommonUserIdentifier = (user: any) => {
  return `${user.uuid}_${user.givenName}_${user.familyName}`;
};

export const extractUserGuid = (json) => {
  let ccJson = null;
  const { resource } = json;
  if (resource) {
    if (isArray(resource) && resource.length === 1) {
      ccJson = camelcaseKeys(resource[0]);
    }
  }
  if (ccJson)
    return [ccJson].map((e) => ({
      userGuid: e.userGuid,
    }))[0];
  return null;
};

export const getUserInfo = async (_user) => {
  // @ts-ignore
  const { app } = defaultStore.getState();
  const { user } = app;
  const baseUrl = getCooeeApiUrl(getHostEnv());
  let response: AxiosResponse;
  try {
    response = await axios.get(urlcat(baseUrl, 'user/info'), {
      headers: {
        Authorization: `${_user ? _user.tokenType : user.tokenType} ${
          _user ? _user.accessToken : user.accessToken
        }`,
      },
    });
    const { data, status } = response;
    if (status === 200) return { ...data?.data };
    Logger.warn('Error getting user info').finally();
  } catch (error) {
    if (error?.response) {
      const { request, ...errorResponse } = error?.response;

      response = errorResponse;
      Logger.warn(response).finally();
    } else Logger.error(error).finally();
  }
  return null;
};
