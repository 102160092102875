import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from '../../utils/reduxStorage';
import app from './appReducer';
import topics from './topicReducer';

const topicStorePersistConfig = {
  key: 'topics',
  storage,
  // blacklist: ['isLoading'],
  stateReconciler: autoMergeLevel2,
};

const appStorePersistConfig = {
  key: 'app',
  storage,
  // blacklist: ['isLoading'],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  app: persistReducer(appStorePersistConfig, app),
  topics: persistReducer(topicStorePersistConfig, topics),
});

export default rootReducer;
