import { differenceBy, isArray, omit, pullAllBy, update } from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import * as types from '../actions/topicActions';
import initialState from '../initialState';

const topicStoreReducer = (state = initialState.topics, action) => {
  switch (action.type) {
    // case HYDRATE: {
    //   return {
    //     ...state,
    //     ...action.payload.appStore,
    //   };
    // }
    case types.UPDATE_TOPICS: {
      // @ts-ignore
      const { topics } = state;
      let tempTopics: any = topics || [];
      const newTopics = differenceBy(
        camelcaseKeys(action.topics),
        topics,
        'uuid'
      );
      if (isArray(action.topics)) {
        action.topics.forEach((t) => {
          tempTopics = tempTopics.map((e: any) =>
            e.uuid === t.uuid
              ? {
                  ...e,
                  ...camelcaseKeys(
                    omit(t, ['channelName', 'nonce', 'createdOn'])
                  ),
                }
              : e
          );
        });
      }
      return {
        ...state,
        topics: tempTopics.concat(newTopics),
      };
    }
    case types.UPDATE_TOPIC: {
      // @ts-ignore
      const { topics } = state;
      if (isArray(action.topic) && action.topic.length > 0) {
        const updatedTopic = camelcaseKeys(action.topic[0]);
        return {
          ...state,
          topics: topics.map((e: any) =>
            e.uuid === updatedTopic.uuid ? { ...e, ...updatedTopic } : e
          ),
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default topicStoreReducer;
