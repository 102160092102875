export const logDnaInstantAlertPrefix = '[INSTANT-ALERT] ';
export const logDna30sAlertPrefix = '[30S-BATCH-ALERT] ';

export const siteTitle = 'Cooee - Work with the best';
export const cooeeFilterTestAccounts = true;

export const providerProfessionalSummaryMaxLength = 888;
export const providerAbnMaxLength = 11;
export const providerTitleMaxLength = 21;
export const providerVideoUrlMaxLength = 50;
export const providerExperienceDescriptionMaxLength = 171;
export const providerTopicMaxLength = 57;
export const photoBoothCountDownStartCount = 5;

export const userDefaultName = 'New User';

// export const profilePhotoSizeRestrictions = {
//   maxHeight: 1025, // in pixels
//   maxWidth: 1025,
//   minHeight: 128,
//   minWidth: 128,
// };

export const profilePhotoSizeRestrictions = {
  maxHeight: 3000, // in pixels
  maxWidth: 4500,
  minHeight: 50,
  minWidth: 90,
};

export const coverPhotoSizeRestrictions = {
  maxHeight: 1118, // in pixels
  maxWidth: 2112,
  minHeight: 768,
  minWidth: 720,
};

export const imageRestrictionFileSizeTooSmall =
  'Selected image file size too small';
export const imageRestrictionFileSizeTooLarge =
  'Selected image file size too large';
export const imageRestrictionImageWidthTooBig = 'Selected image width too big';
export const imageRestrictionImageWidthTooSmall =
  'Selected image width too small';
export const imageRestrictionImageHeightTooBig =
  'Selected image height too big';
export const imageRestrictionImageHeightTooSmall =
  'Selected image height too small';

export const defaultReviewText =
  '“The people who are crazy enough to think they can change the world, are the ones who do.” - Steven Paul Jobs';

export const usernameChangeEmailSupportText =
  'Please email info@oncooee.com if you wish to change your mobile number';
