import axios, { AxiosResponse } from 'axios';
import camelize from 'camelize';
import https from 'https';
import { defaultStore } from '../store/index';
import { getAuthUrl, getCooeeApiUrl, getHostEnv } from './api';
import { extractUser } from './auth';
import {
  logoutUser,
  setShowLoginBox,
  updateUser,
} from '../store/actions/appActions';
import logger from './logger';
import { has, isNil } from 'lodash';

// const dreamFactoryXHeader = 'X-DreamFactory-Session-Token';

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const {
      app: {
        user: { accessToken, tokenType },
      },
    } = defaultStore.getState();
    switch (config.method) {
      case 'post':
        config.headers['Content-Type'] = 'application/json';
        break;
      case 'get':
        config.headers.Accept = 'application/json';
        break;
      default:
        config.headers.Accept = 'application/json';
        break;
    }
    config.httpAgent = new https.Agent({
      rejectUnauthorized: false, //getHostEnv() === 'prod',
    });
    if (!config.url.includes('/auth') && tokenType && accessToken)
      config.headers.Authorization = `${tokenType} ${accessToken}`;
    // logger
    //   .debug(`Request: ${config.url} ${config.method} ${config.data}`)
    //   .finally();
    return config;
  },
  (error) => {
    console.error(error);
    logger.error(error).finally();
    Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response === undefined) {
      // console.log(error);
      // logger.error(error).finally();
      return Promise.reject(error);
    }

    const {
      app: {
        user: { refreshToken },
        userInfo,
      },
    } = defaultStore.getState();
    const { dispatch } = defaultStore;

    let message = '';
    let description = '';

    const { status, data, statusText, headers, config, request } =
      error.response;

    if (has(data, 'error.message')) message = data.error.message;
    if (has(data, 'error.description')) description = data.error.description;

    const originalRequest = error.config;

    // TODO
    // handle blocked for Auth0
    //
    //
    // 'Token refresh failed. Token has expired and can no longer be refreshed',

    // if (
    //   status === 401 &&
    //   message.includes('Token has expired and can no longer be refreshed')
    // ) {
    //   Logger.warn('Token has expired and can no longer be refreshed').finally();
    //   const { dispatch } = defaultStore;
    //   dispatch(logoutUser());
    //   Router.push('/LoginBox');
    //   return Promise.reject(error);
    // }
    // // Token refresh failed. The token has been blacklisted
    // if (status === 401 && message.includes('The token has been blacklisted')) {
    //   Logger.warn(
    //     'Token refresh failed. The token has been blacklisted'
    //   ).finally();
    //   const { dispatch } = defaultStore;
    //   dispatch(logoutUser());
    //   Router.push('/LoginBox').finally();
    //   return Promise.reject(error);
    // }

    // UnauthorizedError: jwt expired
    if (
      status === 401 &&
      description.includes('jwt expired') &&
      !originalRequest._retry
    ) {
      logger
        .debug(`[${userInfo.name || 'Guest'}] Token has expired.`)
        .finally();
      originalRequest._retry = true;

      const authUrl = `${getCooeeApiUrl(getHostEnv())}/auth/token`;

      logger.debug(`[${userInfo.name || 'Guest'}] Renewing token...`).finally();
      axios
        .put(authUrl, { refreshToken })
        .then((response) => {
          const originalRequestClone = { ...originalRequest };
          delete originalRequestClone.Authorization;
          logger
            .debug(
              `[${
                userInfo.name || 'Guest'
              }] Token renewed. Re-sending original request ${JSON.stringify(
                originalRequestClone
              )}`
            )
            .finally();
          const data = camelize(response?.data?.data);
          dispatch(updateUser(data));
          axios.defaults.headers.common.Authorization = data.accessToken;
          return axios(originalRequest);
        })
        .catch((err) => {
          let response;
          if (err?.response) response = err.response;
          const {
            status: innerStatus,
            data: {
              error: { description: innerDescription },
            },
          } = response;
          if (innerStatus === 403) {
            dispatch(logoutUser());
            logger
              .error(
                `[${
                  userInfo.name || 'Guest'
                }] Detected ${innerStatus}: ${innerDescription}`
              )
              .finally();
            dispatch(setShowLoginBox(true));
          }
          // Promise.reject(err);
        });
    }

    // Log generic 400 error
    if (status === 400) {
      console.log(userInfo);
      const error400 = `[${
        userInfo.phone || 'Guest'
      }] ${statusText} (${status}) ${JSON.stringify(error.response)}`;
      console.log(error400);
      logger.error(error400).finally();
      // dispatch(logoutUser());
      return Promise.reject(error);
    }

    // Else
    // const { dispatch } = defaultStore;
    // dispatch(logoutUser());
    // Router.push('/LoginBox');
    return Promise.reject(error);
  }
);

export default axios;
