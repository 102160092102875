const initialState = {
  app: {
    user: {},
    isAuthenticated: false,
    showLoginBox: false,
    userInfo: {},
    appointments: [],
    currentAppointment: {},
    providerInfo: {},
    currentBooking: {},
    modalOptions: {},
    modalContent: {},
    modalAgenda: {},
  },
  topics: [],
};

export default initialState;
