import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import { createWrapper } from 'next-redux-wrapper';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// import loggerMiddleware from './middleware/logger';
// import monitorReducerEnhancer from './enhancers/monitorReducer';
import storage from '../utils/reduxStorage';
import rootReducer from './reducers';

if (process.env.NODE_ENV === 'development') {
  // middlewares.push(secretMiddleware)
}

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['appStore'],
};

const middlewares = [
  // loggerMiddleware,
  // loggerMiddleware,
  thunkMiddleware,
];

const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
let composedEnhancers;

if (process.env.NODE_ENV === 'development') {
  composedEnhancers = composeWithDevTools(...enhancers);
} else {
  composedEnhancers = compose(...enhancers);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const defaultStore = createStore(
  persistedReducer,
  undefined,
  composedEnhancers
);

export const persistor = persistStore(defaultStore);

// const makeStore = context => defaultStore;
const makeStore = () => defaultStore;

export const WrappedStore = createWrapper(makeStore, { debug: false });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (process.env.NODE_ENV !== 'production' && module.hot) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  module.hot.accept('./reducers', () =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultStore.replaceReducer(rootReducer)
  );
}

export default WrappedStore;
