import urlcat from 'urlcat'; // OK to use normal axios
// import { datadogLogs } from '@datadog/browser-logs';
import { isArray, isNil, isObject, isString } from 'lodash';
import axios from './axios';
import { defaultStore } from '../store/index';
import { getCooeeApiUrl, getHostEnv } from './api';
import { logDnaInstantAlertPrefix } from './constants';

// datadogLogs.init({
//   clientToken: 'pub9f0f8e09d5b5c5f42d49267c7ca12072',
//   site: 'datadoghq.com',
//   service: 'cooee-ui',
//   env: 'staging',
//   version: '0.1.0',
//   forwardErrorsToLogs: true,
//   sampleRate: 100,
// });
//
// const datadogLogger = datadogLogs.logger;
// datadogLogger.setLevel('debug');
//

const baseLoggerUrl = 'https://logs.logdna.com/logs/ingest';
const apiKey = '08fa4df064a2acd1352d1f45fca85313';
const headers = {
  'Content-Type': 'application/json; charset=UTF-8',
  // token: Buffer.from('apikey:' + apiKey).toString('base64'),
};

const queryParams = {
  hostname:
    typeof window !== 'undefined' && window.location.hostname
      ? window.location.hostname
      : '',
  // mac: 'test',
  // ip: '121',
  now: ~~(Date.now() / 1000),
  apikey: apiKey,
};

const messageFactory = async (message, timestamp, level = 'INFO') => {
  const {
    app: { userInfo },
  } = defaultStore.getState();
  // if (level === 'ERROR') console.log(message.toString());
  return {
    lines: [
      {
        timestamp: ~~(new Date(timestamp) / 1000),
        line: level === 'ERROR' ? message.toString() : message,
        app: 'cooee-ui',
        level: level.toUpperCase(),
        env: getHostEnv() ? 'development' : 'production',
        meta: userInfo
          ? {
              url: typeof window !== 'undefined' ? window.location : 'Unknown',
              email: userInfo.email,
              username: userInfo.name,
            }
          : {},
      },
    ],
  };
};

const sendToLogDna = async (message) => {
  const fullUrl = urlcat(baseLoggerUrl, queryParams);
  if (message) {
    try {
      const { data } = fetch(fullUrl, {
        method: 'POST',
        headers,
        body: isObject(message) ? JSON.stringify(message) : message,
      });
    } catch (err) {
      console.error(err);
    }
  }
};

// const options = {
//   app: 'Cooee',
//   level: 'debug', // set a default for when level is not provided in function calls
//   sendUserAgent: true,
//   indexMeta: true,
//   env: isDevHost() ? 'development' : 'production',
//   meta: { email: 'test@email.com', username: 'test' },
//   // hostname: '',
//   // ip: '',
//   // mac: '',
// };
//
// const logger = logdna.createLogger('32382651e7573d90eab6154311a008e5', options);
//
// export default logger;

const sendTelemetry = async (payload) => {
  const baseUrl = getCooeeApiUrl(getHostEnv());
  const {
    app: {
      userInfo: { loggingToken },
    },
  } = defaultStore.getState();
  const line = isArray(payload.lines) ? payload.lines[0] : null;
  try {
    await axios.post(urlcat(baseUrl, `telemetry/user`), {
      ...line,
      loggingToken,
    });
  } catch (error) {
    console.error(error);
  }
};

const Logger = () => {
  const info = async (message, opt) => {
    const { telemetry } = isNil(opt) ? { telemetry: false } : opt;
    // console.info(message);
    const payload = await messageFactory(message, new Date(), 'INFO');
    await sendToLogDna(payload);
    if (telemetry) await sendTelemetry(payload);
  };

  const debug = async (message, opt) => {
    const { telemetry } = isNil(opt) ? { telemetry: false } : opt;
    // console.debug(message);
    const payload = await messageFactory(message, new Date(), 'DEBUG');
    await sendToLogDna(payload);
    if (telemetry) await sendTelemetry(payload);
  };

  const warn = async (message, opt) => {
    const { telemetry } = isNil(opt) ? { telemetry: false } : opt;
    // console.warn(message);
    const payload = await messageFactory(message, new Date(), 'WARN');
    await sendToLogDna(payload);
    if (telemetry) await sendTelemetry(payload);
  };

  const warning = warn;

  const error = async (err, opt) => {
    const { telemetry } = isNil(opt) ? { telemetry: false } : opt;
    // console.error(err);
    const payload = await messageFactory(err, new Date(), 'ERROR');
    await sendToLogDna(payload);
    if (telemetry) await sendTelemetry(payload);
  };

  const log = async (message, opt) => {
    const { telemetry, type } = isNil(opt)
      ? { type: '', telemetry: false }
      : opt;
    switch (isString(type) ? type.toUpperCase() : '') {
      case 'DEBUG':
        await debug(message, { telemetry });
        break;
      case 'WARN':
        await warn(message, { telemetry });
        break;
      case 'ERROR':
        await error(message, { telemetry });
        break;
      default:
        await info(message, { telemetry });
    }
  };

  const critical = error;

  return {
    critical,
    info,
    debug,
    warn,
    warning,
    log,
    error,
  };
};

export const logThisRequest = async (data) => {
  const baseUrl = getCooeeApiUrl(getHostEnv());
  try {
    await axios.get(
      urlcat(baseUrl, '/admin/log-this', { source: window.location, ...data })
    );
  } catch (err) {
    await Logger().error(err, { source: window.location });
    await Logger().warn(
      `${logDnaInstantAlertPrefix}Accessed to ${JSON.stringify(
        window.location
      )} denied.`
    );
    throw new Error(`Access denied.`);
  }
};

export default Logger();
