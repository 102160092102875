export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const UPDATE_TOPICS = 'UPDATE_TOPICS';

export const updateTopics = (topics) => ({
  type: UPDATE_TOPICS,
  topics,
});

export const updateTopic = (topic) => ({
  type: UPDATE_TOPIC,
  topic,
});
