import * as types from '../actions/appActions';
import initialState from '../initialState';
import { AppActionTypes } from '../../types/app/app.type';

const appStoreReducer = (state = initialState.app, action: AppActionTypes) => {
  switch (action.type) {
    // case HYDRATE: {
    //   return {
    //     ...state,
    //     ...action.payload.appStore,
    //   };
    // }
    case types.UPDATE_USERINFO: {
      return {
        ...state,
        userInfo: { ...action.userInfo },
      };
    }
    case types.SET_MODAL_CONTENT: {
      return {
        ...state,
        modalContent: { ...state.modalContent, ...action.options },
      };
    }
    case types.SET_MODAL_AGENDA: {
      return {
        ...state,
        modalAgenda: { ...state.modalAgenda, ...action.options },
      };
    }
    case types.SET_MODAL_OPTIONS: {
      return {
        ...state,
        modalOptions: { ...state.modalOptions, ...action.options },
      };
    }
    case types.UPDATE_PROVIDER_INFO: {
      return {
        ...state,
        providerInfo: { ...action.providerInfo },
      };
    }
    case types.UPDATE_USER: {
      if (action.user.refreshToken && action.user.accessToken)
        return {
          ...state,
          user: { ...action.user },
          isAuthenticated: true,
        };
      return {
        ...state,
        // user: {},
        // isAuthenticated: false,
      };
    }
    case types.LOGOUT_USER: {
      return {
        ...state,
        user: initialState.app.user,
        userInfo: initialState.app.userInfo,
        appointments: initialState.app.appointments,
        currentAppointment: initialState.app.currentAppointment,
        providerInfo: initialState.app.providerInfo,
        currentBooking: initialState.app.currentBooking,
        isAuthenticated: false,
        showLoginBox: initialState.app.showLoginBox,
        modalOptions: initialState.app.modalOptions,
      };
    }
    case types.SAVE_APPOINTMENTS: {
      return {
        ...state,
        appointments: action.appointments,
      };
    }
    case types.UPDATE_CURRENT_APPOINTMENT: {
      return {
        ...state,
        currentAppointment: action.appointment,
      };
    }
    case types.SET_SHOW_LOGIN: {
      return {
        ...state,
        showLoginBox: action.show,
      };
    }
    default:
      return state;
  }
};

export default appStoreReducer;
