import logger from './logger';

const apiKey =
  '36fda24fe5588fa4285ac6c6c2fdfbdb6b6bc9834699774c9bf777f706d05a88';

export const getAuthUrl = (isDev) => {
  const devAuthUrl = 'https://api.oncooee.com/api/v2/user/session';
  const prodAuthUrl = 'https://api.oncooee.com/api/v2/user/session';
  if (isDev) return devAuthUrl;
  return prodAuthUrl;
};

export const getLocalApiUrl = (hostEnv) => {
  const devUrl = 'http://localhost:3000/v1';
  const stagingUrl = 'http://localhost:3000/v1';
  const prodUrl = 'http://localhost:3000/v1';

  if (hostEnv === 'dev') return devUrl;
  if (hostEnv === 'staging') return stagingUrl;

  return prodUrl;
};

export const getCooeeApiUrl = (hostEnv: string) => {
  // console.log(`Current environment: ${hostEnv}`);
  const devUrl = 'https://127.0.0.1:8443/v1';
  const stagingUrl = 'https://api-uat.oncooee.com/v1';
  const prodUrl = 'https://api.oncooee.com/v1';

  if (hostEnv === 'dev') return devUrl;
  if (hostEnv === 'staging') return stagingUrl;
  return prodUrl;
};

export const getHostEnv = () => {
  const stagingHosts = ['uat.oncooee.com'];
  const devHosts = ['localhost', '192.168.10.10', '192.168.1.100'];

  const currentHost =
    typeof window !== 'undefined' && window.location.hostname
      ? window.location.hostname
      : '';

  if (devHosts.includes(currentHost) || currentHost === '') return 'dev';
  if (stagingHosts.includes(currentHost)) return 'staging';

  return 'prod';
};
