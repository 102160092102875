import { AppActionTypes } from '../../types/app/app.type';
import {
  Appointment,
  Appointments,
  User,
  UserInfo,
} from '../../interfaces/app/app.interface';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USERINFO = 'UPDATE_USERINFO';
export const UPDATE_PROVIDER_INFO = 'UPDATE_PROVIDER_INFO';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_SHOW_LOGIN = 'SET_SHOW_LOGIN';
export const UPDATE_CURRENT_APPOINTMENT = 'UPDATE_CURRENT_APPOINTMENT';
export const SET_MODAL_OPTIONS = 'SET_MODAL_OPTIONS';
export const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT';
export const SET_MODAL_AGENDA = 'SET_MODAL_AGENDA';
export const SAVE_APPOINTMENTS = 'SAVE_APPOINTMENTS';

export const logoutUser = (): AppActionTypes => ({
  type: LOGOUT_USER,
});

export const updateProviderInfo = (providerInfo) => ({
  type: UPDATE_PROVIDER_INFO,
  providerInfo,
});

export const updateUserInfo = (userInfo: UserInfo): AppActionTypes => ({
  type: UPDATE_USERINFO,
  userInfo,
});

export const saveMeetings = (appointments: Appointments): AppActionTypes => ({
  type: SAVE_APPOINTMENTS,
  appointments,
});

export const updateCurrentAppointment = (
  appointment: Appointment
): AppActionTypes => ({
  type: UPDATE_CURRENT_APPOINTMENT,
  appointment,
});

export const updateUser = (user: User): AppActionTypes => ({
  type: UPDATE_USER,
  user,
});

export const setShowLoginBox = (show: boolean): AppActionTypes => ({
  type: SET_SHOW_LOGIN,
  show,
});

export const setModalOptions = (options: any): AppActionTypes => ({
  type: SET_MODAL_OPTIONS,
  options,
});

export const setModalAgenda = (options: any): AppActionTypes => ({
  type: SET_MODAL_AGENDA,
  options,
});

export const setModalContent = (options: any): AppActionTypes => ({
  type: SET_MODAL_CONTENT,
  options,
});
